<template>
  <div>
    <div class="row">
      <div class="col-md-8 offset-md-2" id="whole">
        <div class="card">
          <div class="card-header">
            <h5>Assign shift to employee</h5>

            <div class="close">
              <router-link :to="{ name: 'assign.shift.list' }">
                <i class="fa fa-times"></i>
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <!-- {{ getLeaveDetails }} -->

            <div class="row">
              <div class="col-lg-8 offset-lg-2 col-md-12" id="d1">
                <form @submit.stop.prevent="onSubmit">
                  <div class="form-group">
                    <label for="">Employee</label>
                    <select
                      class="mb-3 mr-sm-3 mb-sm-0 custom-select"
                      :class="
                        errors
                          ? errors['user_id'] && formdata.user_id == ''
                            ? 'is-invalid'
                            : ''
                          : ''
                      "
                      v-model="formdata.user_id"
                    >
                      <option value="">Choose Employee</option>
                      <option
                        v-for="(empItem, index) in getActiveEmployee"
                        :key="index"
                        :value="empItem.id"
                      >
                        {{ empItem.full_name }}
                      </option>
                    </select>
                    <span
                      class="text-danger text-sm"
                      v-if="errors['user_id'] && formdata.user_id == ''"
                    >
                      {{ errors["user_id"][0] }}</span
                    >
                  </div>

                  <div class="form-group">
                    <label for="">Shift Type</label>
                    <select
                      class="mb-3 mr-sm-3 mb-sm-0 custom-select"
                      :class="
                        errors
                          ? errors['shift_type_id'] &&
                            formdata.shift_type_id == ''
                            ? 'is-invalid'
                            : ''
                          : ''
                      "
                      v-model="formdata.shift_type_id"
                    >
                      <option value="">Select Shift type</option>
                      <option
                        v-for="(sValue, index) in getShiftTypes"
                        :key="index"
                        :value="sValue.id"
                      >
                        {{ sValue.title }}
                      </option>
                    </select>
                    <span
                      class="text-danger text-sm"
                      v-if="
                        errors['shift_type_id'] && formdata.shift_type_id == ''
                      "
                    >
                      {{ errors["shift_type_id"][0] }}</span
                    >
                  </div>

                  <div class="form-group">
                    <label for="">From</label>
                    <input
                      :class="
                        errors
                          ? errors['start'] && formdata.start == ''
                            ? 'is-invalid'
                            : ''
                          : ''
                      "
                      type="date"
                      class="form-control"
                      v-model="formdata.start"
                    />
                    <span
                      class="text-danger text-sm"
                      v-if="errors['start'] && formdata.start == ''"
                    >
                      {{ errors["start"][0] }}</span
                    >
                  </div>

                  <div class="form-group">
                    <label for="">To</label>
                    <input
                      :class="
                        errors
                          ? errors['end'] && formdata.end == ''
                            ? 'is-invalid'
                            : ''
                          : ''
                      "
                      type="date"
                      class="form-control"
                      v-model="formdata.end"
                    />
                    <span
                      class="text-danger text-sm"
                      v-if="errors['end'] && formdata.end == ''"
                    >
                      {{ errors["end"][0] }}</span
                    >
                  </div>

                  <button
                    class="btn btn-success btn-sm float-right"
                    ref="kt_assign_shift_to_user"
                  >
                    Edit request
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
#whole {
  .card {
    .card-header {
      padding: 16px !important;
      display: inline-flex;
      .close {
        border-left: 1px solid #ccc;
        height: 58px;
        width: 58px;
        /* background: black; */
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        text-align: center;
        i {
          line-height: 58px;
          font-size: 25px;

          color: #ccc;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
}
</style>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  ASSIGN_SHIFT_TO_USER,
  FETCH_SHIFT_TYPES,
  FETCH_ACTIVE_EMPLOYEE,
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      errors: [],
      formdata: {
        start: "",
        end: "",
        user_id: "",
        shift_type_id: "",
      },
    };
  },

  mounted() {
    this.$store.dispatch(FETCH_SHIFT_TYPES);
    this.$store.dispatch(FETCH_ACTIVE_EMPLOYEE);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Asigned shift list", route: "/shift/assign/list" },
      { title: "Assign shift to employee" },
    ]);
  },
  methods: {
    onSubmit() {
      const formdata = this.formdata;

      // s spinner to submit button
      const submitButton = this.$refs["kt_assign_shift_to_user"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(ASSIGN_SHIFT_TO_USER, formdata)
        // go to which page after successfully login
        .then((response) => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.formdata.start = "";
          this.formdata.end = "";
          this.formdata.user_id = "";
          this.formdata.shift_type_id = "";
          this.toast("success", "Success!", true, response.message);
          this.errors = [];
          //   this.$router.push({
          //     name: "leave.employee.details",
          //     params: { id: this.formdata.id },
          //   });
        })
        .catch((errors) => {
          this.errors = errors.errors;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.toast("danger", "Error!", true, errors.message);
        });
    },
    toast(variant = null, title, append = false, message) {
      this.$bvToast.toast(message, {
        title: title,
        toaster: "b-toaster-bottom-center",
        variant: variant,
        solid: true,
        appendToast: append,
      });
    },
  },

  computed: {
    ...mapGetters(["getActiveEmployee", "getShiftTypes"]),
  },
};
</script>
